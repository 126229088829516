import markdownit from 'markdown-it';
import sub from 'markdown-it-sub';
import sup from 'markdown-it-sup';
import mark from 'markdown-it-mark';

const md = markdownit('zero')
    .enable([
        'emphasis',
        'strikethrough',
        'backticks',
        'escape',
        'entity',
    ])
    .use(sub)
    .use(sup)
    .use(mark);

export const safeInlineMarkdown = (text: string): string => md.renderInline(text);
